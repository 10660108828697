import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import "../App.css"


const pages = ['Yhteystiedot', 'Tapahtumat', 'KuvaGalleria'];

function ResponsiveAppBar() {

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    // rgb(22, 55, 22) dark green
    // rgb(0, 0, 0) black
    // rgb(66, 146, 66) light green 

    return (
        <AppBar
            position="sticky"
            style={{
                width: '300px',
                backgroundColor: 'rgb(66, 146, 66)',
                boxShadow: '0px 2px 25px rgb(22, 55, 22)',
                marginTop: '1em',
                marginLeft: '1em',
                padding: '.5em',

                borderRadius: '25px',
            }}>
            <Container>
                <Toolbar disableGutters style={{
                    display: 'flex',
                    justifyContent: "space-between",
                    justifyContent: 'flex-start',
                }}>
                    <Box display='flex'>
                        <IconButton style={{ align: 'left' }}
                            size="large"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                        > <MenuOpenIcon /> </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            // anchorOrigin={{
                            //     vertical: 'bottom',
                            //     horizontal: 'left',
                            // }}
                            // keepMounted
                            // transformOrigin={{
                            //     vertical: 'top',
                            //     horizontal: 'left',
                            // }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}

                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">
                                        <Link
                                            //KUN MENU AUKI
                                            style={{ textDecoration: 'none', color: 'black' }}
                                            to={`/${page}`}>
                                            {page}
                                        </Link>
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <div className="font-loader-appbar">
                    <a href="/" style={{fontSize: '250%', textDecoration: 'none', color: 'black', marginLeft: '5px'}}>
                    ValkeeFest</a>
                    </div>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;