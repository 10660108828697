import {Route, Routes, Navigate} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import WebFont from 'webfontloader';
import './App.css';
import ResponsiveAppBar from './components/Nav';
import Tapahtumat from './components/Tapahtumat';
import Yhteystiedot from './components/Yhteystiedot';
import KuvaGalleria from './components/KuvaGalleria'
import Home from './components/Home';
import My404Component from './components/My404Component';
import Arvoitus from './components/Arvoitus'
import Vuosi2021 from './components/vuodet_KUVAT/Vuosi2021_KUVAT';
import Vuosi2022 from './components/vuodet_KUVAT/Vuosi2022_KUVAT';
import Vuosi2023 from './components/vuodet_KUVAT/Vuosi2023_KUVAT';
import Vuosi2024 from './components/vuodet_KUVAT/Vuosi2024_KUVAT';
import Vuosi2025 from './components/vuodet_KUVAT/Vuosi2025_KUVAT';
import Vuosi2026 from './components/vuodet_KUVAT/Vuosi2026_KUVAT';
import RlyFacebook from './components/RlyFacebook';
import latausKuva from './components/pictures/jormy.png'

/** 
* ? Notes:

* TODO: Viimeksi puskettu liveen ja githubiin. Laitettu myös testiryhmille viestiä, että testailevat LOL

* * Voisit puskee ainakin githubiin tän atm! ✅
* * Täytä sitten myös noi muut välilehdet täyteen jotain paskaa! ✅
* * Ei olis huono koittaa tunkee tätä jonnekkin servulle pyörii ja kokeilla miten se toimii? ✅
* * Mutta kunhan jatkat niin on paras ✅
* * Mutta oikeesti, vkpl vois kuitenkin ehkä tätä, eikä opparia LOL ✅
* * Sitaatin jälkeen viiva, ja siitä sitten ohjelma! ✅
* * Vuosille vois kokeilla tehdä myös osallistumis lomakkeen? Joku forms tyylinen setti jotenki? ✅
* * Ota suunnittelu vuosista href pois, eli ei pääse mihkään klikkaamalla ✅ 
* * Hyperlinkki ig yhteystietoihin ✅
* * Fonttien vaihto mobiiliresponsiivisiksi
* * HEI JA SE VITUN DOWNLOAD hässäkkä ois pirun siisti, tyyliin ekat 5 sekkaa jörmyn pää pyörii vaan näytössä!

* ! 404 Page chekki 
* ! Footer maybe? 
* ! Pyydä niitä kuvia pirusti mitä sitten laittaa vuosille! 
* ! Testiryhmien palautteen kautta korjailut

*/



function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simuloi 5 sekunnin latausaikaa
    setTimeout(() => {
      setIsLoading(false); // Aseta isLoading false:ksi, kun lataus on valmis
    }, 2000); // 5000 ms eli 5 sekuntia
  }, []);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Bagel Fat One', 'Rajdhani']
      }
    });
   }, []);

  return (
    <div className="App">
       {isLoading ? (
        // Tässä voit näyttää latausnäytön, esimerkiksi spinnerin tai tekstin
        <div className="loader">
          <p>Ladataan</p>
        <img className="latausKuva" src={latausKuva} />
        </div>
      ) : (
        // Tässä voit näyttää varsinaisen sisällön, kun lataus on valmis
        <>
      <ResponsiveAppBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="Tapahtumat" element={<Tapahtumat />} />
        <Route path="Yhteystiedot" element={<Yhteystiedot />} />
        <Route path="KuvaGalleria" element={<KuvaGalleria />} />
        <Route path="KuvaGalleria/Vuosi2021" element={<Vuosi2021 />} />
        <Route path="KuvaGalleria/Vuosi2022" element={<Vuosi2022 />} />
        <Route path="KuvaGalleria/Vuosi2023" element={<Vuosi2023 />} />
        <Route path="KuvaGalleria/Vuosi2024" element={<Vuosi2024 />} />
        <Route path="KuvaGalleria/Vuosi2025" element={<Vuosi2025 />} />
        <Route path="KuvaGalleria/Vuosi2026" element={<Vuosi2026 />} />
        <Route path="LOL" element={<RlyFacebook />} />
        <Route path="Arvoitus" element={<Arvoitus />} />
        <Route path="404" element={<My404Component />} />
        <Route path='*' element={<Navigate to="/404"/>} />
      </Routes>
      </>
      )}
      </div>
  );
}

export default App;





