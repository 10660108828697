import React from "react";
import img404 from './pictures/404-error-with-landscape-concept-illustration/6325254.jpg'
import "../App.css"


const My404Component = () => {
    return <div className="Error404">

        <img style={{
            marginTop: 50,
            width: '85%',
            height: '60%',
            borderRadius: 10000,
            overflow: 'hidden',
        }} src={img404} alt="Logo" />

        <h1>NO PAGE FOUND</h1>

    </div>
};

export default My404Component;
