
import React from "react";

const Vuosi2024_TAPAHTUMAT = () => {
    return <div className="VuosiTeksti">
        
        <h2>VUOSI 2024</h2>
        <p>Seuraa sosiaalisenmedian kanaviamme saadakseksi päivityksiä</p>
        <p>Täällä kuitenkin julkaistaan tarpeen mukaan asioita</p>



    </div>

};

export default Vuosi2024_TAPAHTUMAT;