import React from 'react'
import '../App.css';

function Arvoitus() {
  return (
    <div>
        <h2>ONNEKSI OLKOON!</h2>
        <h2>LÖYSIT ARVOITUS SIVUN</h2>
        <h3>Vastaus etsimääsi kysymykseen on:</h3>
        <h1><b>1</b></h1>
    </div>
  )
}

export default Arvoitus;