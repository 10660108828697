
import React from "react";

const Vuosi2022_KUVAT = () => {
    return <div className="App">
        
        <h1>VUOSI 2022</h1>
        <p>JOO EI OLE KUVIA VIELÄ ÄLÄ MÄRISE</p>


    </div>

};

export default Vuosi2022_KUVAT;