
import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import logo2021 from 'C:/Users/nicni/Desktop/projektit/omatsivut/src/components/pictures/2021-removebg-preview.png'
import logo2022 from 'C:/Users/nicni/Desktop/projektit/omatsivut/src/components/pictures/2022-removebg-preview.png'
import logo2023 from 'C:/Users/nicni/Desktop/projektit/omatsivut/src/components/pictures/ValkeeLogoFINAL-removebg-preview.png';
import logotbd from 'C:/Users/nicni/Desktop/projektit/omatsivut/src/components/pictures/tbd-removebg-preview.png'
import DoubleArrowTwoToneIcon from '@mui/icons-material/DoubleArrowTwoTone';
import "../App.css"
import { Link } from "react-router-dom";

export default function KuvaGalleria() {

    return (<div>
        <p>Kuvia löytyy myös meidän IG:stä <a href="https://www.instagram.com/valkeefest/" text-decoration="none">ValkeeFest</a></p>
        <ImageList sx={{ width: 1, height: 1 }}>
            {itemData.map((item) => (
                <ImageListItem key={item.img}>
                    <Link to={item.path} underline="hover">
                        <img
                            src={`${item.img}?wfit=crop&auto=format`}
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=3 3x`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </Link>
                    <ImageListItemBar
                        sx={{
                            background:
                                'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                'rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%)',
                            
                        }}
                        title={item.title}
                        position='top'
                        actionIcon={
                            <IconButton
                                sx={{ color: 'white' }}
                                aria-label={`star ${item.title}`}
                            >
                            </IconButton>
                        }
                        actionPosition="left"
                    />
                </ImageListItem>
            ))}
        </ImageList>
        </div>
    );
}

const itemData = [
    {
        img: logo2021,
        path: '/KuvaGalleria/Vuosi2021',
    },
    {
        img: logo2022,
        path: '/KuvaGalleria/Vuosi2022',
    },
    {
        img: logo2023,
        title: '2023',
        path: '/KuvaGalleria/Vuosi2023',
    },
    {
        img: logotbd,
        title: '2024',
       // path: '/KuvaGalleria/Vuosi2024',
    },
    {
        img: logotbd,
        title: '2025',
        // path: '/KuvaGalleria/Vuosi2025',
    },
    {
        img: logotbd,
        title: '2026',
        // path: '/KuvaGalleria/Vuosi2026',
    },
];



