import React from "react";
import Button from '@mui/material/Button';
import "../App.css"


const Home = () => {

    const [openSaateteksti, setopenSaateteksti] = React.useState(false);

    const handlopenSaateteksti = () => {
        setopenSaateteksti(!openSaateteksti);
    };

    return <div className="Home">
        <h1>Tervetuloa ValkeeFestin kotisivuille!</h1>
        <br/>
        <hr/>
        <br/>
        <p>Painamalla alla olevaa "selimin säkit" -nappia, saat yleistietoa tapahtumasta</p>

        <div style={{ display: 'flex', justifyContent: 'center', margin: 'em', }}>
            <Button onClick={handlopenSaateteksti} variant="contained"
                style={{
                    minWidth: '300px',
                    minHeight: '60px',
                    marginTop: '1em',
                    marginLeft: '1em',
                    marginRight: '2em',
                    padding: '.5em',
                    backgroundColor: '#3F9E75',
                    fontSize: '15px'
                }} >SELIMIN SÄKIT</Button>
        </div>
        <br/>
        {openSaateteksti ? (
            <lu>
                <p>ValkeeFest on pieni, mutta intohimoinen kaveriporukan järjestämä musiikki- ja kulttuurifestivaali, joka tarjoaa unohtumattoman kokemuksen kaikille festivaalikävijöille.</p>
                <p>Olemme ylpeitä siitä, että olemme vuosien varrella luoneet ainutlaatuisen ja lämpimän ilmapiirin, jossa musiikki, taide, uskonnollisuus ja yhteisöllisyys kohtaavat.</p>
                <p>Tämä vuosi ei ole poikkeus!</p>
                <p>ValkeeFestin tavoitteena on luoda kaikille festivaalivieraille ainutlaatuinen elämys, ja siksi tarjoamme myös herkullista ruokaa ja juomaa paikallisilta ravintoloilta ja suoraan grillistä. </p>
                <p>Voit rentoutua viihtyisällä festivaalialueella ystäviesi kanssa ja nauttia hyvästä musiikista samalla, kun herkuttelet maukkailla herkuilla.</p>
                <p>Festivaalin tunnelma on lämminhenkinen ja ystävällinen, ja se on aina ollut tärkeä osa ValkeeFestin identiteettiä. Haluamme luoda tilan, jossa voit tuntea olosi tervetulleeksi ja olla oma itsesi. ValkeeFest on enemmän kuin pelkkä mökkiviikonloppu - se on yhteisö, johon kuuluminen tuo iloa ja hyvää energiaa.</p>
                <p>Olemme innoissamme siitä, että voimme toivottaa sinut tervetulleeksi ValkeeFest-festivaalille ja jakaa tämän ainutlaatuisen kokemuksen kanssasi. Tule mukaan ja luo unohtumattomia muistoja ystäviesi kanssa!</p>
                <p>Seuraa nettisivujamme ja sosiaalisen median kanaviamme päivitetyistä tiedoista ja tulevista tapahtumista. Jos sinulla on kysyttävää tai tarvitset lisätietoja, ota rohkeasti yhteyttä - olemme täällä auttaaksemme.</p>
                <p>Nähdään ValkeeFesteillä!</p>
                <p>Ystävällisin terveisin,</p>
                <p>ValkeeFest-tiimi</p>
            </lu>
        ) : null}

<hr/>
<br/>
    </div>
};

export default Home;
