import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import "../App.css"
import { Link } from "react-router-dom";
import IgLogo from "C:/Users/nicni/Desktop/projektit/omatsivut/src/components/pictures/Instagram_logo_2016.svg.webp";
import FbLogo from "C:/Users/nicni/Desktop/projektit/omatsivut/src/components/pictures/fblogo.png";

export default function Yhteystiedot() {

    return <div className="Yhteystiedot">
        <h1 style={{marginTop: '100px'}}>Yhteystiedot</h1>
        <p style={{marginBottom: '50px'}}>Yhteydenotot ensisijaisesti sosiaalisenmedian kanavien kautta, tai puhelimitse</p>
        <hr/>
        <h1 style={{marginTop: '50px'}}>Sosiaalinenmedia</h1>
        <p style={{marginTop: '-20px', fontSize: '15px'}}><i>Josta voi myös olla yhteydessä</i></p>
        <ImageList sx={{ width: 1, height: 150 }}>
            {itemData.map((item) => (
                <ImageListItem key={item.img}>
                    <Link to={item.path} underline="hover">
                        <img
                            src={`${item.img}?wfit=crop&auto=format`}
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=1 1x`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </Link>
                </ImageListItem>
            ))}
        </ImageList>
        <hr/>
        <br/>

        <h1>Tapahtumapaikka</h1>
        <p>You know it</p>
        <br/>
        <hr/>
        <br/>
        <h1>ValkeeFest-tiimi</h1>
        <p style={{marginTop: '0px'}}>Julma-E</p>
        <p style={{marginTop: '-15px'}}>Nöcö</p>
        <p style={{marginTop: '-15px'}}>Selim</p>
        <p style={{marginTop: '-15px'}}>Nipli</p>
        <br/>
        <hr/>
        <br/>
    </div>
};

const itemData = [
    {
        img: IgLogo,
        path: 'https://www.instagram.com/valkeefest/',
    },
    {
        img: FbLogo,
        path: '/LOL',
    },
];


