import Kappa from "./pictures/dota-2-forsen-twitch-emote-kappa-pug-thumbnail-removebg-preview.png"

export default function RlyFacebook() {


    const today = new Date()

    return <div className="RlyFacebook">

    <h1>LOL LUULIT ET MEILLÄ ON FB {today.getFullYear()} </h1>
    <img style={{width:"200px", height:"200px" , borderRadius: "50px"}} src={Kappa}/>
   
    </div>
};