
import React from "react";
import Button from '@mui/material/Button';
import "../App.css";
import Vuosi2023_TAPAHTUMAT from './vuodet_TAPAHTUMAT/Vuosi2023_TAPAHTUMAT'
import Vuosi2024_TAPAHTUMAT from './vuodet_TAPAHTUMAT/Vuosi2024_TAPAHTUMAT'



const Tapahtumat = () => {
    const [open2021, setOpen2021] = React.useState(false);
    const [open2022, setOpen2022] = React.useState(false);
    const [open2023, setOpen2023] = React.useState(false);
    const [open2024, setOpen2024] = React.useState(false);


    const handleOpen2021 = () => {
        setOpen2021(!open2021);
    };

    const handleOpen2022 = () => {
        setOpen2022(!open2022);
    };

    const handleOpen2023 = () => {
        setOpen2023(!open2023);
    };

    const handleOpen2024 = () => {
        setOpen2024(!open2024);
    };

    return <div className="Tapahtumat">
        <div className="NappiTapahtumat">
            <Button onClick={handleOpen2021} variant="contained" disabled
                style={{
                    minWidth: '90%',
                    minHeight: '60px',
                    marginTop: '3em',
                    // marginLeft: '1em',
                    // marginRight: '2em',
                    padding: '.5em',
                }} >2021</Button>
        </div>
        {open2021 ? (
            <ul>
                <p>2021 TAPAHTUMAT</p>
            </ul>
        ) : null}
        <div className="NappiTapahtumat">
            <Button onClick={handleOpen2022} variant="contained" disabled
                style={{
                    minWidth: '90%',
                    minHeight: '60px',
                    marginTop: '2em',
                    // marginLeft: '1em',
                    // marginRight: '2em',
                    padding: '.5em',

                }}>2022</Button>
        </div>
        {open2022 ? (
            <ul>
                <p>2022 TAPAHTUMAT</p>
            </ul>
        ) : null}
        <div className="NappiTapahtumat">
            <Button onClick={handleOpen2023} variant="contained" disabled 
            style={{
                minWidth: '90%',
                minHeight: '60px',
                marginTop: '2em',
                // marginLeft: '1em',
                // marginRight: '2em',
                padding: '.5em',
            }}>2023</Button>
        </div>
        {open2023 ? (
            <ul>
                <Vuosi2023_TAPAHTUMAT />
            </ul>
        ) : null}
        
        
        <div className="NappiTapahtumat">
            <Button onClick={handleOpen2024} variant="contained" style={{
                minWidth: '80%',
                minHeight: '60px',
                marginTop: '2em',
                // marginLeft: '1em',
                // marginRight: '2em',
                padding: '.5em',
                backgroundColor: 'rgb(22, 55, 22)',
                }}
            >2024</Button>
        </div>
        {open2024 ? (
            <ul>
                <Vuosi2024_TAPAHTUMAT/>
            </ul>
        ) : null}
    </div>
};

export default Tapahtumat;

// Buttoneihin voi vain lisätä sitte disabled kun vuosi päättyy, kuten yllä tehty

