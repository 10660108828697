
import React from "react";
import "../../App.css";
import Iframe from 'react-iframe'


const Vuosi2023_TAPAHTUMAT = () => {
    return <div className="VuosiTeksti" >
        {/* <h2>"Vuosi 2023 hehkuu</h2>
        <h2>uskomatonta innostusta,</h2>
        <h2>huikeaa kehitystä ja lumoavaa</h2>
        <h2>positiivisuutta, tarjoten meille </h2>
        <h2>ennennäkemättömän määrän</h2>
        <h2>upeita mahdollisuuksia ja</h2>
        <h2>elämyksiä."</h2>
        <h3> - ChatGPT</h3> */}
        <h2><i>Vuosi 2023 hehkuu
            uskomatonta innostusta,
            huikeaa kehitystä ja lumoavaa
            positiivisuutta, tarjoten meille
            ennennäkemättömän määrän
            upeita mahdollisuuksia ja
            elämyksiä."</i></h2>
        <h3><i>- ChatGPT</i></h3>
        <hr />
        <p>Aikataulu julkaistaan pian</p>
        <hr />
        <h3>PERUSTIEDOT VUODELLE 2023</h3>
        <p>Peruslippujen hinnat tulevat olemaan <b>noin</b> 40-60€</p>
        <p>VIP-lippujen hinnat tulevat olemaan <b>noin</b> 80-120€</p>
        <p style={{ fontSize: '10px', marginTop: '-10px' }}><i>Lievä hintojen nousu edellis vuosiin saattaa johtua pääsponsorimme Jyskän Varastomyymälän lopettamisesta</i></p>
        <p>Hintoihin kuuluu mm:</p>
        <ul>
            <li>Ruoat</li>
            <li>Yöpaikka</li>
            <li>Olympialaisiin osallistumis mahdollisuus</li>
            <li>Sauna</li>
            <li>Paska seura</li>
            <li>Ja mitä muuta nyt edellisinä vuosinakin on kuulunut</li>
        </ul>
        <hr />
        <h3>UUTTA VUONNA 2023</h3>
        <p>Uutuutena vuodelle 2023 esittelemme <b>VIP-liput</b></p>
        <p>VIP lippuja on tarjolla tänä vuonna <b>VAIN</b> 4 kappaletta ja voit osoittaa kiinnostuksesi niiden hankkimiseen alla olevassa Osallistumiskyselyssä!</p>
        <p>Jos halukkaita on useampi, arvomme ostomahdollisuuden halukkaiden kesken</p>
        <p>VIP-lippujen hinta on oletettavastikin hieman normaalia lippua kalliimpi, mutta sisältää mm:</p>
        <ul style={{ marginRight: '5%' }}>
            <li>ValkeeFest Tuotepaketin</li>
            <li>Kokoaikaisen laude paikan</li>
            <li>Vapautuksen siivouksesta</li>
            <li>Hieronnan Selimiltä</li>
            <li>---</li>
            <li>Sekä muuta mitä ValkeeFest-tiimi keksiikään ennen festareita!</li>
        </ul>
        <hr />
        <div>
            <h1>Osallistumiskysely</h1>
            <p>Vastaathan kyselyyn <b>tunnistettavilla</b> tiedoilla ja <b>vain kerran!</b></p>
            <p>Muistathan klikata <b>Lähetä</b> nappia kysymyksien jälkeen!</p>
            <Iframe className="Lomake" width="350px" height="700px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=g5TjqSHdJUy4SCpiXP95Of5KeMhb5KRAl44TZJ0Z8WpUOVJES05ZMExHMzY2TFBZSk9RT1gyU0JSOC4u&embed=true" frameborder="110" marginwidth="110" marginheight="0" style="border: none; max-width:100%; max-height:100vh" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </Iframe>
            <hr/>
            <br/>  
            <p>Jos luomani kaunis embedded toiminto ei toimi sun paska puhelimella niin tässä vielä linkki</p>
            <p>⬇️</p>
            <p><a href="https://forms.office.com/e/3ZY0vc4gyZ">UwU</a></p>
        </div>
        <br />
        <br />
        <hr />
        <br />
        <br />

    </div>
};

export default Vuosi2023_TAPAHTUMAT;





